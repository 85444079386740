const Details = (props) => {
  return (
    <>
      {props.productSummary && <div className="book-summary mb-3" dangerouslySetInnerHTML={{ __html: props.productSummary.replace("<a ", "<a target='_blank' ") }} />}
      {props.productDetail && <div className="mb-5" dangerouslySetInnerHTML={{ __html: props.productDetail.replace("<a ", "<a target='_blank' ") }} />}
      {props.aboutAuthor && <div className="mb-5">
          <h4>About {props.authorNameFL}</h4>
          <div className="author-bio" dangerouslySetInnerHTML={{ __html: props.aboutAuthor.replace("<a ", "<a target='_blank' ") }} />
      </div>}
      {props.marketingPoints && <div className="mb-5">
          <h4>Marketing Points</h4>
          <div dangerouslySetInnerHTML={{ __html: props.marketingPoints.replace("<a ", "<a target='_blank' ") }} />
      </div>}
      {props.videos && props.videos.length > 0 && <div className="mb-5">
          <h4>Videos</h4>
          <div className="videos" id="bookvideostop">
              {props.videos.map(v => {
                  let mp4 = v.toLowerCase().indexOf(".mp4");
                  if (mp4 > -1 && mp4 === v.length - 4) {
                      return (
                          <video controls playsInline key={v}>
                            <source src={v} type="video/mp4" />
                          </video>
                      );
                  }
                  return (
                      <div className="embed-wrapper" key={v}>
                          <iframe src={"https://www.youtube.com/embed/" + v}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen></iframe>
                      </div>
                  );
              })}
          </div>
      </div>}
    </>
  );
}
export default Details;
