import React from 'react';
import { withRouter } from 'react-router';
import { request } from '../functions/apiRequestWrapper';
import Breadcrumb from '../components/Breadcrumb';
import Loader from '../components/Loader';
import BookListItem from '../components/BookListItem';
import Button from 'react-bootstrap/Button';
import { DateTime } from 'luxon';
import { PATH_OFFERS } from '../constants';

import { connect } from 'react-redux';
import { logout } from '../redux/User';
const mapDispatchToProps = dispatch => {
    return {
        logout: () => { dispatch(logout()); }
    }
}

class Offer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: null,
            offer: null
        };
    }

    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
        const {match} = this.props;
        request(
            `${process.env.REACT_APP_API}/1/cms/offers/${match.params.slug}`
        ).then((data) => {
            // console.log(data);
            this.setState({offer: data, isLoading: false});
        }).catch((error) => {
            console.error(error);
            if (error.status === 401) { this.props.logout(); }
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            this.setState({error: msg, isLoading: false});
        });
    }

    render() {
        const {offer, isLoading, error} = this.state;
        var list = false;
        if (offer && (offer.products || offer.catalogues)) {
            list = offer.products || offer.catalogues;
        }
        return (
            <div className="page offer container px-3 px-md-5">
                <Breadcrumb crumbs={[{link: PATH_OFFERS, label: "Offers"}]}>{offer && offer.title ? offer.title : "..."}</Breadcrumb>
                <div className="position-relative">

                    {isLoading && <Loader type="placeholder" />}

                    {(!isLoading && error) && <p className="alert alert-danger my-5">{error}</p>}

                    {offer && <>
                        <div className="row mb-5 offer-featured-item">
                            <div className="col-12 col-sm-6 col-md-8">
                                <div className="featured-img d-sm-none d-md-block" style={{backgroundImage: `url("${offer.imageUrl}")`}} />
                                <div className="featured-img d-none d-sm-block d-md-none" style={{backgroundImage: `url("${offer.thumbnail || offer.imageUrl}")`}} />
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="py-2 py-sm-5 px-sm-2 px-md-3">
                                    <h2><b>{offer.title}</b></h2>
                                    {offer.closingDate &&
                                        <p className="featured-subtitle">Available until {DateTime.fromISO(offer.closingDate).toLocaleString({day: 'numeric', month: 'long', year: 'numeric'})}</p>
                                    }
                                    {offer.rrp && <p className="mb-3">RRP: ${offer.rrp}</p>}
                                    {offer.shortFormUrl &&
                                        <Button variant="primary" href={offer.shortFormUrl} target="_blank" download className="mr-2 mb-2">Order Form</Button>
                                    }
                                    {offer.shortONIXUrl &&
                                        <Button variant="primary" href={offer.shortONIXUrl} target="_blank" download className="mr-2 mb-2">Short ONIX</Button>
                                    }
                                    {offer.longONIXUrl &&
                                        <Button variant="primary" href={offer.longONIXUrl} target="_blank" download className="mr-2 mb-2">Long ONIX</Button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="my-5 offer-description">
                            <div dangerouslySetInnerHTML={{__html: offer.description}} />
                        </div>
                        {list && list.length > 0 && <h4 className="mb-3">Products in this offer</h4>}
                        <div className="row product-row">
                            {list && list.map((product, i) => {
                                return (
                                    <div key={product.isbn} className="col-6 col-sm-4 col-md-3">
                                        <BookListItem className="mb-5" {...product} />
                                    </div>
                                );
                            })}
                        </div>
                    </>}

                </div>
            </div>
        );
    }
}

const routerOffer = withRouter(Offer);
export default connect(undefined, mapDispatchToProps)(routerOffer);
