import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import BookStack from './BookStack';
import { PATH_RELEASES } from '../constants';

const Section = ({monthYear, orderFormId, orderFormTitle, imageUrls, className}) => {

    return (
        <NavLink
            to={`${PATH_RELEASES}/${monthYear}/${orderFormId}`}
            className={classnames("section-list-item", className)}
        >
            <BookStack images={imageUrls} />
            <span className="section-label d-block">
                {orderFormTitle}
            </span>
        </NavLink>
    );
}

export default Section;
