import { request } from '../functions/apiRequestWrapper';
import { logout } from './User';

const SET_LIST = "CAT/SET_LIST";
const CLEAR_LIST = "CAT/CLEAR_LIST";
const SET_PAGE = "CAT/SET_PAGE";
const SET_SORT = "CAT/SET_SORT";
const SET_LOADING = "CAT/SET_LOADING";
const SET_ERROR = "CAT/SET_ERROR";
const SET_SEARCH = "CAT/SET_SEARCH";
const SET_ADV_SEARCH = "CAT/SET_ADV_SEARCH";
const SET_SEARCH_TYPE = "CAT/SET_SEARCH_TYPE";
const SET_TRIGGER_SEARCH = "CAT/SET_TRIGGER_SEARCH";

export function getBooks(_page, _orderBy, _advSearch) {
    return (dispatch, getState) => {
        dispatch({ type: SET_LOADING, payload: true });
        const catalogue = getState().catalogue;
        const user = getState().user;
        let country = user.data && user.data.country ? user.data.country : "AU";
        // let summaryField = country === "AU" ? "ProductSummary" : "ProductSummaryNZ";
        let pubDateField = country === "AU" ? "PublishDate" : "PublishDateNZ";
        let sortField = catalogue.sortField;
        if (sortField === "publishDate" && country === "NZ") { sortField = "PublishDateNZ"; }
        let currentPage = catalogue.page;
        if (_page && typeof _page !== "undefined") {
            currentPage = _page;
            dispatch({ type: SET_PAGE, payload: _page });
        }
        let skip = currentPage > 1 ? catalogue.pageSize * (currentPage - 1) : 0;
        let orderBy = _orderBy || `${sortField} ${catalogue.sortDir}`; // "publishDate desc";
        let q = `?$top=${catalogue.pageSize}&$skip=${skip}&$count=true&$orderby=${orderBy}`;

        if (catalogue.searchType === "simple" && catalogue.search) {
            let s = catalogue.search.replace(/['‘’]/g, "''");
            let authorSearch = '(';
            let splitSearch = s.split(" ");
            for (let n = 0; n < splitSearch.length; n++) {
                authorSearch += (`contains(AuthorName, '${encodeURIComponent(splitSearch[n])}') `);
                if (n < splitSearch.length - 1) {
                  authorSearch += " and ";
                }
            }
            authorSearch += ')';
            s = encodeURIComponent(s);
            //let f = `&$filter=(InStock eq 'In Stock' or InStock eq 'Print On Demand') and (contains(ISBN, '${s}') or contains(Title, '${s}') or ${authorSearch} or contains(${summaryField}, '${s}'))`;
            let f = `&$filter=(ISBN eq '${s}' or contains(Title, '${s}') or ${authorSearch})`;
            // console.log("filter: ", f);
            q = q.concat(f);

        } else {
            if (catalogue.searchType === "advanced") {
                let advSearch = catalogue.advSearch;
                if (_advSearch) {
                    advSearch = _advSearch;
                    dispatch({ type: SET_ADV_SEARCH, payload: _advSearch });
                }
                let filters = [];
                if (advSearch.isbn) {
                    // filters.push(`contains(ISBN, '${encodeURIComponent(advSearch.isbn.replace("'", "''"))}')`);
                    filters.push(`ISBN eq '${advSearch.isbn}'`);
                } else {
                    if (advSearch.title) {
                        filters.push(`contains(Title, '${encodeURIComponent(advSearch.title.replace(/['‘’]/g, "''"))}')`);
                    }
                    if (advSearch.authorName) {
                        let splitName = advSearch.authorName.split(" ");
                        for (let n = 0; n < splitName.length; n++) {
                            filters.push(`contains(AuthorName, '${encodeURIComponent(splitName[n].replace(/['‘’]/g, "''"))}')`);
                        }
                    }
                    
                    // if (advSearch.keyword) {
                    //     filters.push(`contains(${summaryField}, '${encodeURIComponent(advSearch.keyword.replace(/['‘’]/g, "''"))}')`);
                    // }
                    if (advSearch.bicCode) {
                        filters.push(`substring(BICCode,0) eq '${advSearch.bicCode}'`);
                    }
                    if (advSearch.format) {
                        filters.push(`Format eq '${advSearch.format}'`);
                    }
                    if (advSearch.instock) {
                        filters.push(`InStock eq '${advSearch.instock}'`);
                    }
                    if (advSearch.publishDateFromMonth && advSearch.publishDateFromYear) {
                        filters.push(`cast(${pubDateField}, 'Edm.DateTimeOffset') ge ${advSearch.publishDateFromYear}-${advSearch.publishDateFromMonth}-01`);
                    }
                    if (advSearch.publishDateToMonth && advSearch.publishDateToYear) {
                        filters.push(`cast(${pubDateField}, 'Edm.DateTimeOffset') le ${advSearch.publishDateToYear}-${advSearch.publishDateToMonth}-28`);
                    }
                }
                if (filters.length > 0) {
                    let f = `&$filter=${filters.join(' and ')}`;
                    //console.log("filter: ", f);
                    q = q.concat(f);
                }
            }
        }
        request(
            `${process.env.REACT_APP_API}/1/product${q}`
        ).then((data) => {
            if (data.items.length === 0) {
                dispatch({ type: SET_ERROR, payload: "No results found" });
            } else {
                dispatch({ type: SET_LIST, payload: data.items, count: data.count });
            }
        }).catch((error) => {
            console.error(error);
            if (error.status === 401) {
                return dispatch(logout());
            }
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            dispatch({ type: SET_ERROR, payload: msg });
        });
    }
}

export function setSort(field, dir) {
    return (dispatch, getState) => {
        dispatch({ type: SET_SORT, field, dir });
        dispatch(getBooks(undefined, `${field} ${dir}`));
    }
}

export function setSearch(s) {
    return { type: SET_SEARCH, payload: s };
}

export function setSearchType(t) {
    return { type: SET_SEARCH_TYPE, payload: t };
}

export function clearAdvSearch() {
    return (dispatch, getState) => {
        dispatch({ type: SET_ADV_SEARCH, payload: {} });
        dispatch(getBooks());
    }
}

export function clearList() {
    return { type: CLEAR_LIST };
}

export function triggerSearch() {
  return { type: SET_TRIGGER_SEARCH, payload: true };
}

const defaultState = {
    list: [],
    count: 0,
    page: 1,
    pageSize: 20,
    sortField: "publishDate", // "title", "authorName"
    sortDir: "desc", // "asc"
    isLoading: false,
    error: null,
    triggerSearch: false, // for when the search field in the header is used while on the Catalogue page
    search: "", // simple search field value
    advSearch: {}, // advanced search form values
    searchType: "simple", // "advanced"
};

export function catalogueReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_LIST:
            return Object.assign({}, state, {
                list: action.payload,
                count: action.count,
                isLoading: false,
                error: null,
                triggerSearch: false
            });
        case CLEAR_LIST:
            return Object.assign({}, state, {
                list: [],
                count: 0,
                page: 1,
                error: null
            });
        case SET_PAGE:
            return Object.assign({}, state, {
                page: action.payload
            });
        case SET_SORT:
            return Object.assign({}, state, {
                sortField: action.field || state.sortField,
                sortDir: action.dir || state.sortDir
            });
        case SET_LOADING:
            return Object.assign({}, state, {
                isLoading: action.payload
            });
        case SET_ERROR:
            return Object.assign({}, state, {
                error: action.payload,
                list: [],
                isLoading: false
            });
        case SET_SEARCH:
            return Object.assign({}, state, {
                search: action.payload,
                searchType: "simple"
            });
        case SET_ADV_SEARCH:
            return Object.assign({}, state, {
                advSearch: action.payload,
                list: [],
                count: 0
            });
        case SET_SEARCH_TYPE:
            return Object.assign({}, state, {
                searchType: action.payload
            });
        case SET_TRIGGER_SEARCH:
            return Object.assign({}, state, {
                triggerSearch: action.payload
            });

        default:
            return state;
    }
}
