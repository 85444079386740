// import queryString from 'query-string';
import cookie from './cookie';

function handleResponse(response, uri, cache, newAuth) {
  let ok = response.status >= 200 && response.status < 300;
  let contentType = response.headers.get('Content-Type') || '';

  // json response
  if (contentType.includes('application/json')) {
    if (ok) {
      return response.json().then(r => {
          if (cache) {
              let cacheObj = {data: r, timestamp: new Date().getTime()};
              localStorage.setItem(uri, JSON.stringify(cacheObj));
          }
          if (typeof newAuth !== "undefined") {
              r.newAuth = newAuth;
          }
          return r;
      }).catch(() => {
        if (cache) {
            let cacheObj = {data: response, timestamp: new Date().getTime()};
            localStorage.setItem(uri, JSON.stringify(cacheObj));
        }
        return response;
      });
    }
    // error
    if (cache && cache.data && cache.timeDiff && cache.timeDiff < 2592000000) {
        // if there is cache data less than a month old, return that
        return cache.data;
    }
    // no cache data, return an error
    return response.json().then((body) => {
      return Promise.reject({
        status: response.status,
        body,
      });
    });
  }

  // not json response
  if (ok) {
    if (cache) {
        let cacheObj = {data: response, timestamp: new Date().getTime()};
        localStorage.setItem(uri, JSON.stringify(cacheObj));
    }
    return response;
  }
  if (cache && cache.data && cache.timeDiff && cache.timeDiff < 2592000000) {
      // if there is cache data less than a month old, return that
      return cache.data;
  }
  return Promise.reject({
    status: response.status,
    body: response.body
  });
}

function shouldUseCache(uri) {
    // let bookReg = /\/product\/.+/;
    // if (bookReg.test(uri)) {
    //     return true;
    // }
    // if (uri.includes("/releases")) {
    //     return true;
    // }
    if (uri.includes("/cms/")) {
        return true;
    }
    return false;
}

export const request = (uri, options, auth) => {

    let isUsingCache = shouldUseCache(uri);
    let cache = false;
    if (isUsingCache) {
        cache = JSON.parse(localStorage.getItem(uri));
        let now = new Date().getTime();
        if (cache && cache.data && cache.timestamp) {
            cache.timeDiff = now - cache.timestamp;
            let cacheLife = process.env.REACT_APP_ENV === "production" ? 3600000 : 1;
            if (cache.timeDiff < cacheLife) {
                // if cache is less than an hour old, return the cached data
                return Promise.resolve(cache.data);
            }
        } else {
            cache = true;
        }
    }

  // Request config
  let config = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    },
  };
  if (options) {
    config = Object.assign({}, config, options);
  }
  if (auth && auth.accessToken) {
    config.headers.Authorization = `bearer ${auth.accessToken}`;
  } else {
    if (typeof window !== 'undefined' && cookie.getItem('auth')) {
      auth = JSON.parse(cookie.getItem('auth'));
      if (auth.accessToken) {
        config.headers.Authorization = `bearer ${auth.accessToken}`;
      }
    }
  }

  return fetch(uri, config).then((response) => {

    if (response.status === 401 && auth && !uri.includes('logout')) {
      console.log('unauthorised, refresh token and retry');
      return fetch(
        `${process.env.REACT_APP_API}/1/account/token/refresh`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'Authorization': `bearer ${auth.accessToken}`
          },
          body: JSON.stringify({
            clientId: auth.clientId,
            refreshToken: auth.refreshToken,
          })
        }
      ).then((refreshRes) => {
        return refreshRes.json().then(updatedAuth => {
          if (updatedAuth.accessToken) {
            updatedAuth.clientId = auth.clientId;
            cookie.setItem('auth', JSON.stringify(updatedAuth), '', '/');
            config.headers.Authorization = `bearer ${updatedAuth.accessToken}`;
            return fetch(uri, config).then((responseTwo) => {
              return handleResponse(responseTwo, uri, cache, updatedAuth);
            });
          } else {
            return handleResponse(response, uri, cache);
          }
        })
      });
    } else {
      if (auth && auth.accessToken) {
        cookie.setItem('auth', JSON.stringify(auth), '', '/');
      }
      return handleResponse(response, uri, cache);
    }

  });

};
