import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';
import FormText from 'react-bootstrap/FormText';
import PropTypes from 'prop-types';
import _pull from 'lodash/pull';
import _get from 'lodash/get';

const FormField = (props) => {

  const {id, label, type, formikBag, onChange, formatValue, options, inline, hasValidation, text, ...rest} = props;
  const {values, errors, touched, submitCount, setFieldValue, setFieldTouched} = formikBag;

  // allow errors to be a string or array
  let errMsg = "";
  if (errors[id]) {
    if (typeof errors[id] === 'string') {
      errMsg = errors[id];
    } else if (Array.isArray(errors[id])) {
      errMsg = errors[id][0];
    }
  }

  let fieldVal = _get(values, id);
  
  let isTouched = Boolean(touched[id]);
  if (id.indexOf(".") > 0) {
    // this makes some assumptions about where field arrays are used
    let idArr = id.split(".");
    if (touched[idArr[0]] && touched[idArr[0]][parseInt(idArr[1])]) {
      isTouched = true;
    }
  }
  let isValid = Boolean(hasValidation && !errors[id] && isTouched);
  let isInvalid = Boolean(errors[id] && (isTouched || submitCount > 0));

  if (type === "radios") {
    return (
      <FormGroup>
        {label}
        {options.map((opt, i) => {
          return (
            <FormCheck
              key={i}
              inline={inline}
            >
              <FormCheck.Label>
                <FormCheck.Input
                  type="radio"
                  name={id}
                  value={opt.value}
                  checked={opt.value === fieldVal}
                  isInvalid={isInvalid}
                  isValid={isValid}
                  onBlur={() => { setFieldTouched(id); }}
                  onChange={(e) => {
                    let v = e.target.value;
                    console.log("radios onChange", v);
                    if (typeof formatValue === 'function') {
                      v = formatValue(v);
                    }
                    setFieldValue(id, v);
                    onChange(v);
                  }}
                  {...rest}
                />
                {opt.label}
              </FormCheck.Label>
            </FormCheck>
          );
        })}
        {text && <FormText>{text}</FormText>}
        {errMsg && isInvalid && <FormText className="text-danger">{errMsg}</FormText>}
      </FormGroup>
    );
  }

  if (type === "checkboxes") {
    return (
      <FormGroup>
        {label}
        {options.map((opt, i) => {
          let value = fieldVal;
          let isChecked = value && value.indexOf(opt.value) > -1;
          return (
            <FormCheck
              key={i}
              inline={inline}
            >
              <FormCheck.Label>
                <FormCheck.Input
                  type="checkbox"
                  name={id}
                  value={opt.value}
                  checked={isChecked}
                  isInvalid={isInvalid}
                  isValid={isValid}
                  onBlur={() => { setFieldTouched(id); }}
                  onChange={(e) => {
                    let newValue = [...value];
                    if (isChecked) {
                      _pull(newValue, opt.value);
                    } else {
                      newValue.push(opt.value);
                    }
                    setFieldValue(id, newValue);
                    onChange(newValue);
                  }}
                  {...rest}
                />
                {opt.label}
              </FormCheck.Label>
            </FormCheck>
          );
        })}
        {text && <FormText>{text}</FormText>}
        {errMsg && isInvalid && <FormText className="text-danger">{errMsg}</FormText>}
      </FormGroup>
    );
  }

  if (type === "checkbox" || type === "radio" || type === "switch") {
    return (
      <FormCheck
        id={id}
        type={type}
        checked={!!fieldVal}
        label={label}
        onBlur={() => { setFieldTouched(id); }}
        onChange={(e) => {
          let v = !fieldVal;
          setFieldValue(id, v);
          onChange(v);
        }}
        feedback={errMsg}
        isInvalid={isInvalid}
        isValid={isValid}
        inline={inline}
        {...rest}
      />
    );
  }

  if (type === "select") {
    return (
      <FormGroup controlId={id}>
        <FormLabel>{label}</FormLabel>
        <FormControl
          as={type}
          value={fieldVal || ""}
          onBlur={() => { setFieldTouched(id); }}
          onChange={(e) => {
            let v = e.target.value;
            if (typeof formatValue === 'function') {
              v = formatValue(v);
            }
            setFieldValue(id, v);
            onChange(v);
          }}
          isInvalid={isInvalid}
          isValid={isValid}
          {...rest}
        >
          {options.map((opt, i) => {
              if (typeof opt === "string") {
                  return (<option value={opt} key={i}>{opt}</option>);
              }
              return (<option value={opt.value} key={i}>{opt.label}</option>);
          })}
        </FormControl>
        {text && <FormText>{text}</FormText>}
        {errMsg && isInvalid && <FormText className="text-danger">{errMsg}</FormText>}
      </FormGroup>
    );
  }

  if (type === "textarea") {
    return (
      <FormGroup controlId={id}>
        <FormLabel>{label}</FormLabel>
        <FormControl
          as={type}
          value={fieldVal || ""}
          onBlur={() => { setFieldTouched(id); }}
          onChange={(e) => {
            let v = e.target.value;
            if (typeof formatValue === 'function') {
              v = formatValue(v);
            }
            setFieldValue(id, v);
            onChange(v);
          }}
          isInvalid={isInvalid}
          isValid={isValid}
          {...rest}
        />
        {text && <FormText>{text}</FormText>}
        {errMsg && isInvalid && <FormText className="text-danger">{errMsg}</FormText>}
      </FormGroup>
    );
  }

  return (
    <FormGroup controlId={id}>
      <FormLabel>{label}</FormLabel>
      <FormControl
        type={type}
        value={fieldVal || ""}
        onBlur={() => { setFieldTouched(id); }}
        onChange={(e) => {
          let v = e.target.value;
          if (typeof formatValue === 'function') {
            v = formatValue(v);
          }
          setFieldValue(id, v);
          onChange(v);
        }}
        isInvalid={isInvalid}
        isValid={isValid}
        {...rest}
      />
      {text && <FormText>{text}</FormText>}
      {errMsg && isInvalid && <FormText className="text-danger">{errMsg}</FormText>}
    </FormGroup>
  );
}

FormField.propTypes = {
  id: PropTypes.string.isRequired,
  formikBag: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf(["text", "textarea", "select", "checkbox", "radio", "switch", "checkboxes", "radios", "password"]),
  onChange: PropTypes.func,
  formatValue: PropTypes.func,
  options: PropTypes.array,
  inline: PropTypes.bool,
  hasValidation: PropTypes.bool
};

FormField.defaultProps = {
  type: "text",
  onChange: () => {},
}

export default FormField;
