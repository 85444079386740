import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from "react-router";
import queryString from 'query-string';
import Loader from '../../components/Loader';
import Logo from '../../components/Logo';
import UpdateVersion from '../../components/UpdateVersion';

import LoginForm from './components/LoginForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import RequestConfirmationEmailForm from './components/RequestConfirmationEmailForm';
import EmailConfirmation from './components/EmailConfirmation';

import './login.scss';

import {
  PATH_HOME,
  PATH_LOGIN,
  PATH_FORGOT_PASS,
  PATH_RESET_PASS,
  PATH_ACTIVATE,
  PATH_REQUEST_CONFIRMAION,
  PATH_CONFIRM_EMAIL,
  pathRequiresAuth
} from '../../constants';

import { connect } from 'react-redux';
import { fetchProfile, logout } from '../../redux/User';
const mapStateToProps = state => {
  return {
    isLoadingProfile: state.user.isLoading,
    data: state.user.data,
    initLoadDone: state.user.initLoadDone,
    isAuthed: state.user.isAuthed
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchProfile: (cb, errCb, auth) => { dispatch(fetchProfile(cb, errCb, auth)); },
    logout: () => { dispatch(logout()); }
  }
}

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
    }
  }

  componentDidMount() {
    if (this.props.data && this.props.data.firstName) {
      // profile loaded, redirect
      setTimeout(this.closeLogin, 3000);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data === null && this.props.data && this.props.data.firstName) {
      // profile loaded, redirect
      setTimeout(this.closeLogin, 3000);
    }
  }

  closeLogin = () => {
    let { from } = this.props.location.state || { from: { pathname: PATH_HOME } };
    if (!this.props.isAuthed && pathRequiresAuth(from.pathname)) {
      return;
    }
    this.props.history.push(from);
  }

  goTo = (path, step) => {
    let { from } = this.props.location.state || { from: { pathname: PATH_HOME } };
    this.props.history.push({ pathname: path, state: { from: from, step: step } });
  }

  render() {
    const { data, initLoadDone } = this.props;

    if (!initLoadDone) {
      // initial page load, the app is checking for a cookie and attempting to load the profile
      return (
        <div className="page login">
          <div className="text-center text-light py-5"><Logo variant="large" /></div>
          <div className="container">
            <Loader type="placeholder" />
          </div>
        </div>
      );
    }

    if (data && data.firstName) {
      // profile data received
      return (
        <div className="page login">
          <div className="text-center text-light py-5"><Logo variant="large" /></div>
          <div className="container">
            <Loader type="placeholder">
              <p><b>Welcome, {data.firstName} {data.lastName}</b></p>
            </Loader>
          </div>
        </div>
      );
    }

    // no profile data received, continue with rendering forms

    let { from } = this.props.location.state || { from: { pathname: PATH_HOME } };

    let queryParams = queryString.parse(this.props.location.search);

    return (
      <div className="page login">
        <div className="text-center text-light py-5"><Logo variant="large" /></div>

        <div className="container mb-5">
        <div className="bg-white">
        <Switch>
          <Route path={PATH_LOGIN}>
            <LoginForm
              initialValues={{ username: this.state.username }}
              onChangeUsername={(v) => { this.setState({ username: v }); }}
              fetchProfile={this.props.fetchProfile}
              onLoadProfile={() => { setTimeout(() => { this.props.history.replace(from); }, 3000); }}
              goTo={this.goTo}
            />
          </Route>

          <Route path={PATH_REQUEST_CONFIRMAION}>
            <RequestConfirmationEmailForm
              initialValues={{ username: this.state.username }}
              onChangeUsername={(v) => { this.setState({ username: v }); }}
              goTo={this.goTo}
            />
          </Route>

          <Route path={PATH_FORGOT_PASS}>
            <ForgotPasswordForm
              initialValues={{ username: this.state.username }}
              onChangeUsername={(v) => { this.setState({ username: v }); }}
              goTo={this.goTo}
            />
          </Route>

          <Route path={[PATH_RESET_PASS, PATH_ACTIVATE]}>
            <ResetPasswordForm
              goTo={this.goTo}
              id={queryParams.id}
              token={queryParams.token || this.props.match.params?.code}
              isActivation={this.props.match.path === PATH_ACTIVATE}
            />
          </Route>

          <Route path={PATH_CONFIRM_EMAIL}>
            <EmailConfirmation
              goTo={this.goTo}
              id={queryParams.id}
              token={queryParams.token}
            />
          </Route>
        </Switch>
        </div>
        </div>
        
        <UpdateVersion />

      </div>
    );
  }
}

const RouterLoginPage = withRouter(LoginPage);
export default connect(mapStateToProps, mapDispatchToProps)(RouterLoginPage);
