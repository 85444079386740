import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import Loader from '../../../components/Loader';
import Button from 'react-bootstrap/Button';
import { request } from '../../../functions/apiRequestWrapper';

import {
  PATH_LOGIN
} from '../../../constants';

class ForgotPasswordForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      isSubmitting: false,
      serverError: null
    }
  }

  submitForm = (values, formikBag) => {
    this.setState({isSubmitting: true, serverError: null});
    var payload = {...values, source: "SS"};
    request(
      `${process.env.REACT_APP_API}/1/account/password/request`,
      {
        method: 'POST',
        body: JSON.stringify(payload)
      }
    ).then((response) => {
      this.setState({step: 2, isSubmitting: false});
    }).catch((error) => {
      this.setState({ step: 2, isSubmitting: false });
    });
  }

  validateForm = (values) => {
      let errors = {};
      if (!values.email || values.email === "") {
          errors.email = "Please enter your email";
      }
      return errors;
  }

  render() {
    const { step } = this.state;
    return (
      <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={this.props.initialValues}>
        {(formikBag) => (
          <Form className="p-5 position-relative">

            {this.state.isSubmitting && <Loader />}

            <h3 className="mb-4">Forgotten your password?</h3>

            {step === 1 && <>
              <p className="mb-3">Enter your registered email to reset your password.</p>
              <FormField id="email" label="Email" formikBag={formikBag} onChange={this.props.onChangeEmail} hasValidation />
              <Button type="submit" variant="primary">Reset Password</Button>
              {this.state.serverError && <p className="server-error py-3">{this.state.serverError}</p>}
            </>}

            {step === 2 && <>
              <p className="mb-3">If this user account exists, an email will be in your inbox shortly.</p>
              <Button variant="primary" onClick={() => { this.props.goTo(PATH_LOGIN, 1); }}>Login</Button>
            </>}

          </Form>
        )}
      </Formik>
    );
  }
}

ForgotPasswordForm.propTypes = {
  goTo: PropTypes.func,
  onChangeEmail: PropTypes.func,
  initialValues: PropTypes.object,
}

export default ForgotPasswordForm;
