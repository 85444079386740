import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import { Globe } from 'react-bootstrap-icons';
import {
    PATH_HOME,
    PATH_HELP,
    PATH_CONTACT
} from '../constants';
import UpdateVersion from './UpdateVersion';

import { connect } from 'react-redux';
const mapStateToProps = state => {
    return {
        country: state.user.data && state.user.data.country ? state.user.data.country : null
    };
}

const Footer = (props) => {
    return (
        <div id="footer" className="bg-dark text-light py-3">
            <div className="container px-3 px-md-5">
                <Navbar className="px-0 py-0 mb-3 flex-wrap" variant="dark">
                    <Link to={PATH_HOME} className="navbar-brand order-0"><Logo /></Link>
                    <Nav className="mr-auto">
                        <Link to={PATH_HELP} className="nav-link">Help</Link>
                        <Link to={PATH_CONTACT} className="nav-link">Contact</Link>
                    </Nav>
                    <Nav className="flex-wrap">
                        <Nav.Link href="https://www.simonandschuster.com.au" target="_blank" rel="noreferrer" className="text-nowrap pr-sm-0"><Globe /> www.simonandschuster.com.au</Nav.Link>
                    </Nav>
                </Navbar>
                <div className="text-center text-sm-right">
                    <p className="mb-2">&copy; {new Date().getFullYear()} Simon & Schuster, Inc. All rights reserved.</p>
                    <p>ABN 84 000 945 380</p>
                </div>
            </div>
            <UpdateVersion />
        </div>
    );
}

export default connect(mapStateToProps)(Footer);
